<template>
  <div>
    <div class="modal_inputs">
      <FormKit
        id="shipping-scanner"
        v-model="order"
        label="Order Id"
        placeholder="Scan a Scancode"
        type="text"
        @input="debounceUpdate"
      />
      <FormKit v-model="isChecked" label="Do not print a packing slip" type="checkbox" />
    </div>
    <div class="modal_actions">
      <Button text="Submit" @click="scanOrder" />
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { useAppStoreActions } from "@/hooks/store/useApp";
import { isVerifiedOrderApi } from "@/api/shipping";
import { SCAN_CODE_DELAY } from "@/consts/scanCode";
import Button from "@/components/UI/Form/Button/Button.vue";
import debounce from "lodash.debounce";
import { useRouter, useRoute } from "vue-router";
import { verifyAndRoute } from "@/utils/shippingUtils";

const { close, open } = useModal();
const order = ref("");
const { setLoading } = useAppStoreActions();
const router = useRouter();
const route = useRoute();
const isChecked = ref(false);

const scanOrder = () => {
  if (order.value) {
    setLoading(true);
    close(null);
    const data = {
      status: "burning",
      binning: 1,
      in_status: 1,
      ids: order.value.split("-")[0],
    };
    // eslint-disable-next-line no-use-before-define
    debounceUpdate.cancel();
    isVerifiedOrderApi(data)
      .then((res) => {
        setLoading(true);
        verifyAndRoute(res.data, order.value, open, isChecked.value, route.path, router, false);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

const debounceUpdate = debounce(scanOrder, SCAN_CODE_DELAY);

onMounted(() => {
  nextTick(() => {
    const shippingScanner = document.getElementById("shipping-scanner");
    shippingScanner.focus();
  });
});
</script>

<style lang="scss" scoped></style>
