<template>
  <div class="modal_inputs w-full grid gap-4 overflow-y-auto overflow-x-hidden mb-[50px]">
    <h4 class="text-2xl font-bold">Main</h4>
    <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-4 items-center">
      <FormLabel v-for="item in filteredInfo" :key="item.label" :label="item.label">
        {{ item.value }}
      </FormLabel>
      <FormLabel
        v-for="(order = {}, index) in orderData.products"
        :key="order.name"
        :label="`Product ${index + 1} flag`"
        >{{ order.flag }}
      </FormLabel>
    </div>
    <h4 class="text-xl font-bold">Shipping</h4>
    <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-4 items-center">
      <FormLabel v-for="item in shipping" :key="item.label" :label="item.label">
        {{ item.value }}
      </FormLabel>
    </div>
    <h4 class="text-xl font-bold">Orders</h4>
    <div v-for="(product, idx) in products" :key="product.id" class="grid grid-cols-3 gap-4">
      <div class="col-span-3 border-b border-[#E8ECEE] py-2 mb-4 text-base font-bold">
        Product {{ idx + 1 }}
      </div>
      <div class="col-span-1">
        <img
          :src="product.image"
          alt="main pic"
          class="border border-[#E8ECEE] rounded w-[196px] h-[256px] object-contain mb-2"
        />
        <img
          :src="product.imageExtra"
          alt="extra pic"
          class="border border-[#E8ECEE] rounded w-[196px] h-[129px] object-contain"
        />
        <div v-if="product.background">
          <h4 class="text-base font-bold my-1">Keep background</h4>
          <p class="text-sm">
            {{ product.background.price }}
          </p>
        </div>
        <div v-if="product.digitalPreview">
          <h4 class="text-base font-bold my-1">Digital preview enabled</h4>
          <p class="text-base">
            {{ product.digitalPreview.price }}
          </p>
        </div>
      </div>
      <div class="col-span-2">
        <h4 class="text-base font-bold mb-1">Product name</h4>
        <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
          <FormLabel v-for="item in product.details" :key="item.label" :label="item.label">
            {{ item.value }}
          </FormLabel>
        </div>
        <div v-if="product.rectangle">
          <h4 class="text-base font-bold mb-1 mt-4">Rectangle Keychains</h4>
          <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
            <FormLabel label="Qty">
              {{ product.rectangle.qty }}
            </FormLabel>
            <FormLabel label="Cost">
              {{ product.rectangle.price }}
            </FormLabel>
          </div>
        </div>
        <div v-if="product.heart">
          <h4 class="text-base font-bold mb-1 mt-4">Heart Keychains</h4>
          <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
            <FormLabel label="Qty">
              {{ product.heart.qty }}
            </FormLabel>
            <FormLabel label="Cost">
              {{ product.heart.price }}
            </FormLabel>
          </div>
        </div>
        <div v-if="product.lightbase">
          <h4 class="text-base font-bold mb-1 mt-4">Light bases</h4>
          <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
            <FormLabel label="Type">
              {{ product.lightbase.type }}
            </FormLabel>
            <FormLabel label="Qty">
              {{ product.lightbase.qty }}
            </FormLabel>
            <FormLabel label="Cost">
              {{ product.lightbase.price }}
            </FormLabel>
          </div>
        </div>
        <div v-if="product.inscription">
          <h4 class="text-base font-bold mb-1 mt-4">Inscription</h4>
          <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
            <FormLabel label="Inscription 1">
              {{ product.inscription.first }}
            </FormLabel>
            <FormLabel label="Inscription 2">
              {{ product.inscription.second }}
            </FormLabel>
            <FormLabel label="Cost">
              {{ product.inscription.price }}
            </FormLabel>
          </div>
        </div>
        <div v-if="product.metal_plate_for_base">
          <h4 class="text-base font-bold mb-1 mt-4">Metallic Plate for Base</h4>
          <div class="grid grid-cols-[minmax(30px,150px)_1fr] gap-1 items-center">
            <FormLabel label="Metallic Plate for Base">
              {{ product.metal_plate_for_base.value }}
            </FormLabel>
            <FormLabel label="Cost">
              {{ product.metal_plate_for_base.price }}
            </FormLabel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal_actions">
    <Button secondaryButton text="Edit order" @click="cancel" />
    <Button class="z-10" text="Submit order" @click="save" />
  </div>
</template>

<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import FormLabel from "@/components/UI/Form/FormLabel.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { jsonToFormData } from "@/utils/jsonToFormData";
import { removeEmpty } from "@/utils/removeEmptyProperty";
import { createOrderApi } from "@/api/order";
import { useRouter } from "vue-router";
import { useAppStoreActions } from "@/hooks/store/useApp";
import useToast from "@/hooks/useToast";
import { computed } from "vue";
import { usePreviewOrder } from "./usePreviewOrder";

const props = defineProps({
  orderData: Object,
  submit: Function,
});

const { setLoading } = useAppStoreActions();
const { close } = useModal();
const router = useRouter();
const { openToast } = useToast();

const createOrder = () => {
  setLoading(true, { text: "Creating order. Please wait" });
  const entries = jsonToFormData(props.orderData);
  removeEmpty(entries);
  return createOrderApi(entries)
    .then((res) => {
      router.push(`/crm/orders/order/${res.data.orderId[0]}`);
    })
    .catch((e) => {
      openToast(`Order creation error: ${JSON.stringify(e)}`, "danger");
    })
    .finally(() => setLoading(false));
};

const { shipping, info, products } = usePreviewOrder(props.orderData);

const filteredInfo = computed(() => info.filter((item) => item.value !== ""));
const save = () => {
  createOrder();
  close(null);
};

const cancel = () => {
  close(null);
};
</script>
