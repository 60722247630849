import { ENTRY_POINTS } from "@/views/Shipping/Shipping.consts";
import { VERIFICATION_STATES } from "@/components/Shipping/OrdersList/OrderList.consts";

/*
 * This function is used to load the shipping params.
 * @param {object} currenRoute - The current route
 * @returns {object} - The page mode and the scanned order
 */
function loadShipping(currenRoute, callback) {
  const pageMode = currenRoute.query.prevPage;
  const scanned = currenRoute.query.gship_scanned;

  if (pageMode === ENTRY_POINTS.BINNING) {
    callback({ ids: currenRoute.params.id, isBinning: true }, pageMode, scanned);
  } else {
    callback({ ids: currenRoute.params.id }, pageMode, scanned);
  }

  return { pageMode, scanned };
}

/*
 * This function is used to route to the next page after the check of the
 * current page.
 * @param {string} path - The path to the next page
 * @param {string} param - The param to add to the path
 * @param {string} order - The order to add to the query
 * @param {string} currentPath - The current path
 * @param {object} router - The router object
 * @returns {void}
 * @example
 * */
function routeAfterCheck(path, param, order, currentPath, router) {
  const currentPathWithoutId = `${currentPath.split("/").splice(0, 3).join("/")}/`; // current path without id for check

  router
    .push({
      path: `${path}${param}`,
      query: { prevPage: ENTRY_POINTS.G_SHIP, gship_scanned: order },
    })
    .then(() => {
      if (currentPathWithoutId === path) {
        router.go(0);
      }
    });
}

/*
 * This function is used to verify the data and route to the next page.
 * @param {object} data - The data to verify
 * @param {string} order - The order to add to the query
 * @param {object} open - The open function from the useModalStore
 * @param {object} isChecked - The isChecked object from the useShippingStore
 * @param {string} routePath - The path to the next page
 * @param {object} router - The router object
 * @returns {void}
 */
const verifyAndRoute = (data, order, open, isChecked, routePath, router) => {
  const shippingPath = "/crm/shipping/";
  const gatherBinningPath = "/crm/gather-binning/";
  const shippingLabel = `/files/plabel/${data.gid.join("-")}.pdf`;
  const currentParam = order.split("-")[0];

  // if data.is_shipped is true, then the order has already been shipped
  if (data.is_shipped) {
    open({
      title: "Already Shipped",
      component: "StatusWarning",
      props: {
        message: "This item was already shipped. Are you sure you want to remake the waybill? Y/N",
        scanned: order,
        binning: data.binning,
        checked: isChecked.value,
        gid: data.gid,
      },
    });
    // if data.orders.length is not 0, then the order has not been marked as "Laser Completed – Ready to Pack" or "Packed for Shipping" yet
  } else if (data.orders.length !== 0) {
    open({
      title: "Status Warning",
      component: "StatusWarning",
      props: {
        message:
          'Unique order ID has not been marked "Laser Completed – Ready to Pack" or "Packed for Shipping" yet',
        scanned: order,
        binning: data.binning,
        checked: isChecked,
        gid: data.gid,
      },
    });
    // if data.binning.binning is !== 0, it should be sent to the gather binning page
  } else if (data.binning.binning !== 0) {
    routeAfterCheck(gatherBinningPath, currentParam, order, routePath, router);
    // if data.binning.binning is 0, it should be sent to the shipping page
  } else {
    // if isChecked.value is true, then the user does not want to print a packing slip
    if (!isChecked && data.gid.length > 0) {
      window.open(shippingLabel);
    }
    routeAfterCheck(shippingPath, currentParam, order, routePath, router);
  }
};

const checkVerification = (orders) =>
  Object.values(orders).every((item) => item.status === VERIFICATION_STATES.VERIFIED);

export { loadShipping, routeAfterCheck, verifyAndRoute, checkVerification };
