import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { getToken } from "@/utils/auth";
import Login from "../views/Login/Login.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    name: "status-statistics",
    path: "/status-statistics",
    component: () =>
      import(/* webpackChunkName: "crm-main" */ "../views/StatusStatistics/StatusStatistics.vue"),
  },
  {
    name: "status-statistics-date",
    path: "/status-statistics-date",
    component: () =>
      import(
        /* webpackChunkName: "crm-main" */ "../views/StatusStatistics/StatusStatisticsDate.vue"
      ),
  },
  {
    path: "/crm",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "crm" */ "../views/Dashboard/Dashboard.vue"),
    children: [
      {
        name: "Chat",
        path: "/crm/chat",
        component: () => import(/* webpackChunkName: "crm-main" */ "../components/Chat/Chat.vue"),
      },

      {
        name: "Charts",
        path: "/crm/panel",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Charts/Charts.vue"),
        meta: {
          breadcrumbs: [
            {
              name: "Charts",
            },
          ],
        },
      },
      {
        name: "Orders",
        path: "/crm/orders",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
        meta: {
          breadcrumbs: [
            {
              name: "Orders",
            },
          ],
        },
      },
      {
        name: "Order Statuses",
        path: "/crm/orders/statuses",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/OrderStatuses/OrderStatuses.vue"),
        meta: {
          breadcrumbs: [
            {
              name: "Orders",
              to: "/crm/orders",
            },
            {
              name: "Order Statuses",
              to: "",
            },
          ],
        },
      },
      {
        name: "New order",
        path: "/crm/orders/new-order",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/NewOrder/NewOrder.vue"),
      },
      {
        name: "Edit Order",
        path: "/crm/orders/edit-order/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/EditOrder/EditOrder.vue"),
      },
      {
        name: "Order details",
        path: "/crm/orders/order/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/OrderPage/OrderPage.vue"),
      },
      {
        name: "Products",
        path: "/crm/products",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/Inventories/Inventories.vue"),
      },
      {
        name: "Sellers",
        path: "/crm/sellers",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Sellers/Sellers.vue"),
      },
      {
        name: "New Seller",
        path: "/crm/new-seller",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/NewSeller/NewSeller.vue"),
      },
      {
        name: "Edit Seller",
        path: "/crm/edit-seller/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/EditSeller/EditSeller.vue"),
      },
      {
        name: "About Seller",
        path: "/crm/seller/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/AboutSeller/AboutSeller.vue"),
      },
      {
        name: "Tools",
        path: "/crm/tools",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
      },
      {
        name: "Reports",
        path: "/crm/reports",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
      },
      {
        path: "/crm/clock",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
      },
      {
        path: "/crm/settings",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
      },
      {
        path: "/crm/upgrades",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Orders/Orders.vue"),
      },
      {
        name: "Production",
        path: "/crm/production",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/Production/Production.vue"),
      },
      {
        name: "Laser Order",
        path: "/crm/laser-table/:id",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Laser/Laser.vue"),
      },
      {
        name: "Laser Orders",
        path: "/crm/laser-table",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Lasers/Lasers.vue"),
      },
      {
        name: "Binning",
        path: "/crm/binning",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Binning/Binning.vue"),
      },
      {
        name: "Shipping",
        path: "/crm/shipping/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/Shipping/Shipping.vue"),
      },
      {
        name: "Cubby",
        path: "/crm/cubby",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Cubby/Cubby.vue"),
      },
      {
        name: "Stores",
        path: "/crm/stores",
        component: () => import(/* webpackChunkName: "crm-main" */ "../views/Stores/Stores.vue"),
      },
      {
        name: "About Store",
        path: "/crm/store/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/AboutStore/AboutStore.vue"),
      },
      {
        name: "New Store",
        path: "/crm/store/new-store",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/NewStore/NewStore.vue"),
      },
      {
        name: "Edit Store",
        path: "/crm/store/edit-store/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/NewStore/NewStore.vue"),
      },
      {
        name: "Gather Binning",
        path: "/crm/gather-binning/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/GatherBinning/GatherBinning.vue"),
      },
      {
        name: "Remake Order",
        path: "/crm/orders/remake-order/:id",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/RemakeOrder/RemakeOrder.vue"),
      },
      {
        name: "Inventories",
        path: "/crm/inventories",
        component: () =>
          import(/* webpackChunkName: "crm-main" */ "../views/Inventories/Inventories.vue"),
        children: [
          {
            name: "Inventories",
            path: ":id",
            component: () =>
              import(/* webpackChunkName: "crm-main" */ "../views/Inventories/Inventory.vue"),
          },
        ],
      },
      {
        name: "Verify Order",
        path: "/crm/orders/verification/:id",
        component: () =>
          import(
            /* webpackChunkName: "crm-main" */ "../views/VerificationOrder/VerificationOrder.vue"
          ),
        beforeEnter: (to, from, next) => {
          const isRedirected = from.name === "Order details" || from.name === "Orders";
          if (isRedirected) {
            next();
          } else {
            next({ name: "Orders" });
          }
        },
      },
    ],
    meta: {
      needsAuth: true,
    },
  },
  {
    path: "/icons",
    component: () =>
      import(
        /* webpackChunkName: "crm-main" */ "../components/UI/Iconography/IconsList/IconsList.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const DEFAULT_TITLE = "HQ";

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.title = to.name || DEFAULT_TITLE;

    const isLoggedIn = !!getToken();
    if (to.meta.needsAuth && !isLoggedIn) {
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(null, "", "/");
      next("/");
    }

    next();
  });
});

export default router;
