<template>
  <form action="false" @submit.prevent="handleSubmitChangeStatus">
    <div class="modal_inputs">
      <FormKit
        v-model="selectedStatus"
        :options="statuses"
        label="Select Status"
        placeholder="Status"
        type="select"
      ></FormKit>
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button :disabled="!selectedStatus" text="Change Status" type="submit" />
    </div>
  </form>
</template>

<script setup>
import Button from "@/components/UI/Form/Button/Button.vue";
import { useChangeStatus } from "./ChangeStatus.hook";

const props = defineProps({
  id: Array,
});

const { statuses, selectedStatus, handleSubmitChangeStatus, handleCancel } = useChangeStatus(props);
</script>
