<template>
  <form actions="false" @submit.prevent="handleSubmitShipByUber">
    <div class="modal_inputs">
      <FormKit
        v-model="formData.charge"
        label="Total Charge"
        placeholder="Total charge"
        type="text"
      />
      <FormKit
        v-model="formData.trackingNumber"
        label="Tracking Number"
        placeholder="Tracking number"
        type="text"
      />
      <FormKit
        v-model="formData.trackingUrl"
        label="Tracking URL"
        placeholder="Tracking URL"
        type="textarea"
      />
    </div>
    <div class="modal_actions">
      <Button secondary-button text="Cancel" @click="handleCancel"></Button>
      <Button text="Ship by Uber" type="submit" />
    </div>
  </form>
</template>

<script setup>
import { ref, toRefs } from "vue";
import Button from "@/components/UI/Form/Button/Button.vue";
import { useModal } from "@/components/UI/Modal/useModal";
import { shipByUberApi } from "@/api/shipping";
import { useRouter } from "vue-router";
import { useAppStoreActions } from "@/hooks/store/useApp";
import useToast from "@/hooks/useToast";
import { ENTRY_POINTS } from "@/views/Shipping/Shipping.consts";

const { setLoading } = useAppStoreActions();
const router = useRouter();
const { close, open } = useModal();
const { openToast } = useToast();

const props = defineProps({
  ids: {
    type: Array,
  },
  pageMode: {
    type: String,
  },
});

const { ids, pageMode } = toRefs(props);

const INITIAL_STATE = {
  charge: null,
  trackingNumber: "",
  trackingUrl: "",
};

const formData = ref(INITIAL_STATE);

const handleSubmitShipByUber = () => {
  close(null);
  setLoading(true);
  shipByUberApi({
    tracking_number: formData.value.trackingNumber,
    orderIds: ids.value,
    tracking_url: formData.value.trackingUrl,
    total_charge: formData.value.charge,
  })
    .then(() => {
      openToast("Order will be shipped by Uber", "success");
      router.push("/crm/orders");
      if (pageMode.value === ENTRY_POINTS.G_SHIP) {
        open({
          title: "Shipping",
          component: "Shipping",
        });
      }
    })
    .catch((err) => {
      openToast(err.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

const handleCancel = () => {
  close(null);
};
</script>

<style lang="scss" scoped></style>
