import {
  INITIAL_RECTANGLE_KEYCHAIN_PRICE,
  INITIAL_HEART_KEYCHAIN_PRICE,
  INITIAL_INSCRIPTION_PRICE,
  INITIAL_KEEP_BACKGROUND_PRICE,
  INITIAL_DIGITAL_PREVIEW_PRICE,
  INITIAL_PERSONAL_NOTE_PRICE,
  INITIAL_METALLIC_PLATE_PRICE,
} from "./orderInitialValues";

export const newOrderFormInputs = {
  type: "0",
  seller_id: null,
  transactionId: null,
  user_id: 0,
  team_id: 0,
  verify_by: "artist",
  store_id: "20289",
  currency: "USD",
  name: null,
  email: null,
  phone: null,
  shipping_type: "Air",
  service_id: 1,
  country_id: "CA",
  state_prov_region: null,
  city: null,
  address_line_1: null,
  address_line_2: null,
  postcode: null,
};

export const newOrderProductInputs = {
  product: "",
  qty: null,
  boxQty: null,
  price: null,
  productDiscount: false,
  productDiscountType: 0,
  productDiscountPrice: null,
  clientId: null,

  mainPhoto: null,
  extraPhoto: null,
  photoLater: null,

  flag: null,

  rectangleQty: null,
  rectanglePrice: INITIAL_RECTANGLE_KEYCHAIN_PRICE,
  rectangleDiscount: false,
  rectangleDiscountType: 0,
  rectangleDiscountPrice: null,

  heartQty: null,
  heartPrice: INITIAL_HEART_KEYCHAIN_PRICE,
  heartDiscount: false,
  heartDiscountType: 0,
  heartDiscountPrice: null,

  lightBaseType: "",
  lightBaseQty: null,
  lightBasePrice: null,
  lightBaseDiscount: false,
  lightBaseDiscountType: 0,
  lightBaseDiscountPrice: null,

  inscriptionFirst: null,
  inscriptionSecond: null,
  inscriptionPrice: INITIAL_INSCRIPTION_PRICE,
  inscriptionDiscount: false,
  inscriptionDiscountType: 0,
  inscriptionDiscountPrice: null,

  background: false,
  backgroundPrice: INITIAL_KEEP_BACKGROUND_PRICE,
  backgroundDiscount: false,
  backgroundDiscountType: 0,
  backgroundDiscountPrice: null,

  digitalPreview: false,
  digitalPreviewPrice: INITIAL_DIGITAL_PREVIEW_PRICE,
  digitalPreviewDiscount: false,
  digitalPreviewDiscountType: 0,
  digitalPreviewDiscountPrice: null,

  notes: false,
  notesText: null,
  notesPrice: INITIAL_PERSONAL_NOTE_PRICE,
  notesDiscount: null,
  notesDiscountType: 0,
  notesDiscountPrice: null,

  metallicPlate: false,
  metallicPlateText: null,
  metallicPlatePrice: INITIAL_METALLIC_PLATE_PRICE,
  metallicPlateDiscount: null,
  metallicPlateDiscountType: 0,
  metallicPlateDiscountPrice: null,
};

// rewrite this
export const SHIPPING_SPEED_OPTIONS = [
  { label: "24H", value: "5", id: 0 },
  { label: "48H", value: "4", id: 1 },
  { label: "3...5D", value: "-4", id: 2 },
  { label: "5...7D", value: "-6", id: 3 },
  { label: "7...10D", value: "-7", id: 4 },
  { label: "14...21D", value: "-14", id: 5 },
];

export const LIGHTBASE_TYPE_OPTIONS = [
  { label: "Rotating Light Base", value: "Rotating Light Base" },
  { label: "Square Light Base", value: "Square Light Base" },
  { label: "Rectangle Light Base", value: "Rectangle Light Base" },
  { label: "Large Wooden Light Base", value: "Large Wooden Light Base" },
  { label: "Medium Wooden Light Base", value: "Medium Wooden Light Base" },
  { label: "Small Wooden Light Base", value: "Small Wooden Light Base" },
  { label: "Mini Wooden Light Base", value: "Mini Wooden Light Base" },
  { label: "Concave Lightbase", value: "Concave Lightbase" },
  { label: "Ornament Stand", value: "Ornament Stand" },
];

export const ORDER_TYPES = [
  { label: "Quick order entry", value: "0" },
  { label: "Quote", value: "1" },
  { label: "Quote – RAW Material", value: "2", disabled: true },
  { label: "Quick order – RAW Material", value: "3", disabled: true },
];

export const STORE_TYPES = [
  { label: "HQ", value: "20289" },
  { label: "Phone or chat", value: "20290" },
];
export const SHIPPING_TYPES = [
  { label: "Air", value: "Air" },
  { label: "Ground", value: "Ground" },
];

export const CURRENCY_TYPES = [
  { label: "USD", value: "USD", symbol: "$" },
  { label: "CAD", value: "CAD", symbol: "СA$" },
  { label: "GBP", value: "GBP", symbol: "£" },
  { label: "AUD", value: "AUD", symbol: "A$" },
  { label: "AED", value: "AED", symbol: "AED " },
];

export const HQ_TYPES = [
  { label: "Asia", value: 2368 },
  { label: "UK", value: 2633 },
  { label: "Canada", value: 0 },
];

export const VERIFY_BY_TYPES = [
  { label: "Artist", value: "artist" },
  { label: "Toronto", value: "hq" },
];
